import {
  Form,
  useActionData,
  redirect,
  Link,
  useNavigation,
} from "@remix-run/react";
import {
  BASE_URL,
  API_VERSION,
  FETCH_DATA,
  FORMAT_PHONE_NUMBER,
  BRAND_ID,
} from "../config/constants";
import { useState } from "react";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";

export let action = async ({ request }) => {
  const url = new URL(request.url);
  const redirectToUrlAfterLogin = url.searchParams.get("redirect_to");

  const formData = await request.formData();
  const phoneNumber = formData.get("phoneNumber");

  if (!phoneNumber) return { error: "Phone number is required" };

  const verifyData = {
    phone: phoneNumber.replace(/\D/g, ""),
    device_id: "111",
    device_token: "111",
    platform: "web",
  };

  const mobileVerificationUrl = `${BASE_URL}/${BRAND_ID}/${API_VERSION}/user_web_authentication/mobileVerification`;

  const verifyResponse = await FETCH_DATA(
    mobileVerificationUrl,
    "data",
    "POST",
    verifyData,
    "application/x-www-form-urlencoded"
  );

  if (verifyResponse.success) {
    // Redirect to /verify page and pass the phone number as a query parameter
    if (redirectToUrlAfterLogin) {
      return redirect(
        `/verify?phoneNumber=${phoneNumber}&redirect_to=${redirectToUrlAfterLogin}`
      );
    } else {
      return redirect(`/verify?phoneNumber=${phoneNumber}`);
    }
  } else {
    return { error: verifyResponse.message || "Verification failed" };
  }
};

export let meta = ({ data }) => {
  const canonicalUrl = `https://ourismarket.com/login`;
  return [
    { title: "Login | Ouri's Market" },
    {
      name: "description",
      content:
        "Log in to your Ouri's Market account to manage your orders, access your favorites, and enjoy a seamless shopping experience.",
    },
    { name: "robots", content: "noindex, nofollow" },
    { property: "og:title", content: "Login | Ouri's Market" },
    {
      property: "og:description",
      content:
        "Log in to your Ouri's Market account to manage your orders, access your favorites, and enjoy a seamless shopping experience.",
    },
    { property: "og:url", content: canonicalUrl },
    { rel: "canonical", href: canonicalUrl },
  ];
};

export default function Login() {
  const actionData = useActionData();
  const navigation = useNavigation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = FORMAT_PHONE_NUMBER(e.target.value);
    setPhoneNumber(formattedPhoneNumber);

    // Check if it's a valid 10-digit phone number
    if (formattedPhoneNumber.replace(/[^\d]/g, "").length === 10) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const isButtonDisabled = navigation.state === "submitting" || !isPhoneValid;

  return (
    <div>
      <Header />
      <div className="max-w-[500px] mx-auto px-[10px] lg:px-8 border-l border-r border-[#f0f0f0]">
        <div className="py-6 lg:py-12">
          <h1 className="text-center text-black text-2xl lg:text-3xl mb-8">
            Login
          </h1>
          <Form method="post" className="flex flex-col">
            <label className="flex flex-col items-center my-4 text-black">
              <div className="mb-4">Enter your phone number</div>
              <div className="flex gap-2 w-full">
                <div className="bg-[#f0f0f0] text-base w-[50px] h-[50px] rounded-[10px] flex justify-center items-center">
                  +1
                </div>
                <input
                  type="text"
                  onChange={handlePhoneNumberChange}
                  value={phoneNumber}
                  name="phoneNumber"
                  required
                  className="h-[50px] bg-white px-[12px] text-base flex-grow border-2 border-solid border-[#f0f0f0] rounded-[10px]"
                  placeholder="Your Phone Number"
                />
              </div>
            </label>
            <button
              type="submit"
              disabled={isButtonDisabled}
              className="rounded-[10px] text-lg bg-primary disabled:bg-[#f0f0f0] text-body disabled:text-[#666666] focus:text-body flex justify-center items-center gap-2 w-full h-[52px]"
            >
              {navigation.state === "submitting"
                ? "Sending..."
                : "Send Verification Code"}
            </button>
          </Form>

          <div className="text-base mt-[24px] text-black text-center">
            Don't have an account yet?{" "}
            <Link
              to="/register"
              className="underline text-black focus:text-gold hover:text-gold"
            >
              Register
            </Link>
          </div>

          {actionData?.error && (
            <p className="text-red-500">{actionData.error}</p>
          )}
          {actionData?.success && (
            <p className=" text-green">
              Verification code sent to {actionData.phoneNumber}
            </p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
